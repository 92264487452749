import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {
    InfiniteData,
    useInfiniteQuery,
    FetchNextPageOptions,
    InfiniteQueryObserverResult,
    UseInfiniteQueryOptions
} from 'react-query';

import { ApiResponse } from '../api/ApiResponse.types';

type UsePaginationOptions<T extends Record<string, unknown>> = {
    initialData?: InfiniteData<ApiResponse<unknown, T>>;
    elementIdOrClass?: string;
    enabled?: boolean;
} & UseInfiniteQueryOptions;

type UsePaginationReturnType<T extends Record<string, unknown>> = {
    data: InfiniteData<ApiResponse<unknown, T>>;
    isFetching: boolean;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    remainingItemCount?: number;
    ref: (node?: Element) => void;
    fetchNextPage: (
        options?: FetchNextPageOptions
    ) => Promise<InfiniteQueryObserverResult<ApiResponse<unknown, T>, unknown>>;
    remove: () => void;
    refetch: () => void;
    isLoading: boolean;
};

export function usePagination<T extends Record<string, unknown>>(
    dependencies: (string | unknown)[],
    apiFunction: (pageParam?: number) => Promise<ApiResponse<unknown, T> | undefined | null>,
    options?: UsePaginationOptions<T>
): UsePaginationReturnType<T> {
    const { ref, inView, entry } = useInView();

    useEffect(() => {
        if (!entry?.target && options?.elementIdOrClass) {
            ref(
                document.getElementById(options.elementIdOrClass) ??
                    document.getElementsByClassName(options.elementIdOrClass)?.[0]
            );
        }
    });

    const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, hasPreviousPage, remove, refetch } =
        useInfiniteQuery<ApiResponse<unknown, T> | undefined | null>(
            dependencies,
            async ({ pageParam }) => {
                return (await apiFunction((pageParam as unknown as number) ?? 1)) || undefined;
            },
            {
                enabled: options?.enabled !== false,
                keepPreviousData: true,
                staleTime: 5000,
                refetchOnWindowFocus: false,
                initialData: options?.initialData,
                getPreviousPageParam: (firstPage: ApiResponse<unknown, T>) => {
                    return firstPage && firstPage._page && firstPage._page > 0 ? firstPage._page - 1 : undefined;
                },
                getNextPageParam: (lastPage: ApiResponse<unknown, T>) => {
                    return lastPage && lastPage._page < lastPage._page_count ? lastPage._page + 1 : undefined;
                },
                ...options
            }
        );

    useEffect(
        () => {
            if (inView && hasNextPage) {
                fetchNextPage().then();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inView]
    );

    const pages = data?.pages?.filter((page) => page) ?? [];
    const totalItems = data?.pages?.[0]?._total_items ?? null;
    const currentItemCount = pages?.reduce((count, page) => count + findItems(page).length, 0) ?? null;
    function findItems(page: ApiResponse<unknown, T>): unknown[] {
        if (!page?._embedded) {
            return [];
        }
        return Object.values(page._embedded).find((obj) => obj instanceof Array) as unknown[];
    }

    const remainingItemCount = totalItems && pages ? Math.max(0, totalItems - currentItemCount) : null;

    return {
        ref: ref,
        data: {
            pages: pages,
            pageParams: data?.pageParams?.filter((pageParam) => pageParam) ?? []
        },
        isFetching: isFetching,
        isFetchingNextPage: isFetchingNextPage,
        hasNextPage: isFetching ? undefined : hasNextPage,
        hasPreviousPage: hasPreviousPage,
        fetchNextPage: fetchNextPage,
        remove: remove,
        remainingItemCount: remainingItemCount,
        refetch: refetch,
        isLoading: isFetching && pages.length <= 0
    };
}
