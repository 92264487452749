import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { tailwindColors } from '@/src/helper/tailwindConfigHelper';

export enum DropdownStyle {
    default = 'default',
    light = 'light',
    dropdown_on_white_bg = 'dropdown_on_white_bg',
    light_on_white_bg = 'light_on_white_bg'
}

export type DropdownOption = {
    value: string;
    label: string;
    image?: string | StaticImageData;
};

export type DropdownNumberOption = {
    value: number;
    label: string;
};

export type DropdownProps = {
    className?: string;
    style?: DropdownStyle;
    placeholder?: string | JSX.Element;
    currentValue?: DropdownOption | DropdownNumberOption;
    options?: DropdownOption[] | DropdownNumberOption[];
    onChange?: (selectedValue) => void;
    inputRef?;
};

function Dropdown(props: DropdownProps): JSX.Element {
    const { style, currentValue, onChange, inputRef: propInputRef } = props;

    const localInputRef = useRef(null);
    const inputRef = !propInputRef ? localInputRef : propInputRef;

    const [currentValueInternal, setCurrentValueInternal] = useState(currentValue);
    useEffect(() => {
        inputRef.current.value = currentValueInternal;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValueInternal]);

    let inputColor = tailwindColors.white.DEFAULT;
    let providedColor = tailwindColors.white.DEFAULT;
    let singleValueColor = tailwindColors.white.DEFAULT;
    let controlBackgroundColor = tailwindColors.darkBlue.transparent;
    let controlBorderColor = tailwindColors.darkBlue.transparent;
    let controlIsFocusedBorderColor = tailwindColors.cyan.DEFAULT;
    let controlHoverBorderColor = tailwindColors.cyan.DEFAULT;
    let menuBackgroundColor = tailwindColors.darkBlue.DEFAULT;
    let optionBorderColor = tailwindColors.cyan.DEFAULT;
    let optionBackgroundColor = null;
    let optionIsFocusedBackgroundColor = tailwindColors.cyan.DEFAULT;
    let optionColor = tailwindColors.white.DEFAULT;
    let optionIsFocusedColor = tailwindColors.darkBlue.DEFAULT;

    switch (style) {
        case DropdownStyle.dropdown_on_white_bg:
            inputColor = tailwindColors.darkBlue.DEFAULT;
            providedColor = tailwindColors.darkBlue.DEFAULT;
            singleValueColor = tailwindColors.darkBlue.DEFAULT;
            controlBackgroundColor = tailwindColors.darkBlue.supertransparent;
            controlBorderColor = tailwindColors.transparent;
            controlIsFocusedBorderColor = tailwindColors.darkBlue.DEFAULT;
            controlHoverBorderColor = tailwindColors.darkBlue.DEFAULT;
            menuBackgroundColor = tailwindColors.white.DEFAULT;
            optionBorderColor = tailwindColors.cyan.DEFAULT;
            optionBackgroundColor = tailwindColors.darkBlue.supertransparent;
            optionIsFocusedBackgroundColor = tailwindColors.cyan['transparent-20'];
            optionColor = tailwindColors.darkBlue.DEFAULT;
            optionIsFocusedColor = tailwindColors.darkBlue.DEFAULT;
            break;
        case DropdownStyle.light:
            inputColor = tailwindColors.white.DEFAULT;
            providedColor = tailwindColors.white.DEFAULT;
            singleValueColor = tailwindColors.white.DEFAULT;
            controlBackgroundColor = tailwindColors.white['20'];
            controlBorderColor = tailwindColors.white.transparent;
            controlIsFocusedBorderColor = tailwindColors.cyan['on-white-bg'];
            controlHoverBorderColor = tailwindColors.cyan['on-white-bg'];
            menuBackgroundColor = tailwindColors.white['20'];
            optionBorderColor = tailwindColors.cyan.DEFAULT;
            optionIsFocusedBackgroundColor = tailwindColors.white['20'];
            optionColor = tailwindColors.white.DEFAULT;
            optionIsFocusedColor = tailwindColors.white.DEFAULT;
            break;
        case DropdownStyle.light_on_white_bg:
            inputColor = tailwindColors.white.DEFAULT;
            providedColor = tailwindColors.white.DEFAULT;
            singleValueColor = tailwindColors.white.DEFAULT;
            controlBackgroundColor = tailwindColors.white['20'];
            controlBorderColor = tailwindColors.white.transparent;
            controlIsFocusedBorderColor = tailwindColors.cyan['on-white-bg'];
            controlHoverBorderColor = tailwindColors.cyan['on-white-bg'];
            menuBackgroundColor = tailwindColors.white['100'];
            optionBorderColor = tailwindColors.cyan.DEFAULT;
            optionIsFocusedBackgroundColor = tailwindColors.cyan['transparent-20'];
            optionColor = tailwindColors.darkBlue.DEFAULT;
            optionIsFocusedColor = tailwindColors.darkBlue.DEFAULT;
            break;
        default:
            break;
    }

    const customStyles = {
        input: (base) => ({
            ...base,
            color: inputColor,
            height: '100%'
        }),
        provided: (base) => ({
            ...base,
            color: providedColor
        }),
        singleValue: (provided) => ({
            ...provided,
            color: singleValueColor
        }),
        control: (base, { isFocused }) => ({
            ...base,
            background: controlBackgroundColor,
            borderColor: isFocused ? controlIsFocusedBorderColor : controlBorderColor,
            '&:hover': {
                borderColor: controlHoverBorderColor
            },
            boxShadow: 'none',
            height: 'inherit'
        }),
        menu: (base) => ({
            ...base,
            marginTop: 0,
            background: menuBackgroundColor
        }),
        menuList: (base) => ({
            ...base,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0
        }),
        option: (styles, { isFocused }) => ({
            ...styles,
            borderColor: optionBorderColor,
            backgroundColor: isFocused ? optionIsFocusedBackgroundColor : optionBackgroundColor,
            color: isFocused ? optionIsFocusedColor : optionColor
        })
    };

    function onChangeInternal(item) {
        setCurrentValueInternal(item);

        if (onChange) {
            onChange(item);
        }
    }

    return (
        <Select
            {...props}
            value={currentValueInternal}
            styles={customStyles}
            onChange={onChangeInternal}
            ref={inputRef}
        />
    );
}

Dropdown.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
    inputRef: PropTypes.any
};

export default Dropdown;
